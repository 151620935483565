:root {
    --primary-color: #1E4F76 !important;
    --secondary-color: #7EA00E !important;
    --danger-color: #CB3939 !important;
  }

.main-text {
      color: var(--primary-color) !important;
}

.error-text {
      color: var(--danger-color) !important;
}
.error-bold-text  {
    font-weight: bolder;
    font-size: larger;
    margin: 3%;
}

.align-left {
    text-align: left !important;
    padding-left: 0 !important;
}

.light-text {
    font-weight: 10 !important;
    text-indent: 0 !important;
    font-size: 20px !important;
    color: rgba(128, 128, 128, 0.585) !important;
}

.input-icon {
    color: var(--primary-color) !important;
}


.text-button {
    cursor: pointer !important;
    color: var(--primary-color) !important; 
    margin-left: 25% !important; 
}

.text-button:hover {
    color: var(--secondary-color) !important; 
}

[class*="primary"] {
    background-color: var(--primary-color) !important;
}

[class*="secondary"] {
    background-color: var(--secondary-color) !important;
}

[class*="danger"] {
    background-color: var(--danger-color) !important;
}
